import { useRef } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CandidateSubmissionStatusChangeForm, Flex } from '@components';

import { SubmissionStatus } from '@constants';
import { ICandidateSubmission } from '@types';

export const SubmissionStatusChangeModal: React.FC<{
  submission: ICandidateSubmission;
  nextSubmissionStatus: SubmissionStatus | null;
  onClose: () => void;
}> = ({ submission, nextSubmissionStatus, onClose }) => {
  const submissionStatusFormComponentRef = useRef<{
    updateSubmissionStatus: () => Promise<void>;
  }>();

  const onSubmissionStatusChangeClick = async () => {
    await submissionStatusFormComponentRef.current?.updateSubmissionStatus();
  };

  return (
    <Dialog open={!!nextSubmissionStatus} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">
            {SubmissionStatus.Rejected === nextSubmissionStatus
              ? 'Reject candidate'
              : 'Set interview scheduled date'}
          </Typography>
          <CloseIcon
            onClick={onClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '16px 24px', paddingTop: '16px !important' }}
      >
        <CandidateSubmissionStatusChangeForm
          submission={submission}
          status={nextSubmissionStatus!}
          ref={submissionStatusFormComponentRef}
        />
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmissionStatusChangeClick}>
            Save
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
