import {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useAppDispatch } from '@redux/hooks';
import { useFormik } from 'formik';

import { Box, Typography, createFilterOptions } from '@mui/material';
import { AutocompleteTextfield, DateTimeRangePicker, Flex } from '@components';

import { DateTimeRange, ICandidateSubmission } from '@types';
import { SubmissionClosingReasons, SubmissionStatus } from '@constants';
import {
  markSubmissionAsRejected,
  setSubmissionScheduledSlot,
} from '@redux/submission';

import { submissionStatusChangeValidationSchema } from './validationSchema';

export const CandidateSubmissionStatusChangeForm: React.FC<{
  submission: ICandidateSubmission;
  status: SubmissionStatus;
  ref: any;
}> = forwardRef(({ submission, status }, ref) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<{
    status: SubmissionStatus | null;
    closingReason: string | null;
    scheduledSlot: DateTimeRange | null;
  }>({
    initialValues: {
      status,
      closingReason: null,
      scheduledSlot: submission.interview?.requestedSlot?.[0] || null,
    },
    validationSchema: submissionStatusChangeValidationSchema,
    onSubmit: async (values) => {
      if (values.status === SubmissionStatus.Rejected) {
        await dispatch(
          markSubmissionAsRejected({
            submissionId: submission.id,
            closingReason: values.closingReason || '',
          }),
        );
      }
      if (values.status === SubmissionStatus.CallScheduled) {
        await dispatch(
          setSubmissionScheduledSlot({
            submissionId: submission.id,
            scheduledSlot: values.scheduledSlot!,
          }),
        );
      }
    },
    validateOnMount: true,
    enableReinitialize: false,
  });

  useImperativeHandle(ref, () => ({
    updateSubmissionStatus: async (): Promise<void> => {
      await formik.handleSubmit();
    },
  }));

  useEffect(() => {
    formik.setFieldValue('status', status);
  }, [status]);

  if (
    ![SubmissionStatus.Rejected, SubmissionStatus.CallScheduled].includes(
      status,
    )
  )
    return null;

  const closingReasonError =
    (formik.touched.closingReason || !!formik.submitCount) &&
    formik.errors.closingReason
      ? formik.errors.closingReason
      : null;

  const scheduledSlotError =
    (formik.touched.scheduledSlot || !!formik.submitCount) &&
    formik.errors.scheduledSlot
      ? formik.errors.scheduledSlot
      : null;

  return (
    <Box
      width="100%"
      sx={{ background: '#FFF', padding: '16px', marginBottom: 2 }}
    >
      {SubmissionStatus.Rejected === status && (
        <AutocompleteTextfield
          name="closingReason"
          label="Closing reason"
          value={formik.values.closingReason}
          options={SubmissionClosingReasons}
          required
          onBlur={() => formik.setFieldTouched('closingReason')}
          fullWidth
          freeSolo
          filterSelectedOptions
          clearOnBlur
          selectOnFocus
          error={!!closingReasonError}
          helperText={closingReasonError || undefined}
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(`Add "${inputValue}"`);
            }

            return filtered;
          }}
          onChange={(e: SyntheticEvent, newValue: string | null) => {
            const nextClosingReason = newValue?.includes('Add "')
              ? /Add "(.*)"/gi.exec(newValue)?.[1]
              : newValue;

            formik.setFieldValue('closingReason', nextClosingReason);
          }}
        />
      )}
      {SubmissionStatus.CallScheduled === status && (
        <Flex flexDirection="column" alignItems="start">
          <DateTimeRangePicker
            timeZone={submission.interview?.clientTimezone || ''}
            date={formik.values.scheduledSlot || undefined}
            handleChange={(date) => formik.setFieldValue('scheduledSlot', date)}
            flex={1}
          />
          {scheduledSlotError ? (
            <Typography variant="subtitle2" color="text.danger">
              {scheduledSlotError}
            </Typography>
          ) : (
            <Typography variant="subtitle2" color="text.secondary">
              Please specify time in the client's timezone
            </Typography>
          )}
        </Flex>
      )}
    </Box>
  );
});
