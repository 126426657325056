import {
  AllClosedStatuses,
  CandidateStatus,
  CandidateStatusFormConfig,
  CandidateStatusStage,
  CandidateStatusesByStage,
  EngineeringCandidateStagesOrder,
  EnineeringSpecializations,
  ExcludedStatusesByStatus,
  ExcludedTransitionStatuses,
  NonEngineeringCandidateStagesOrder,
  PassedStatusesByStage,
  ProcessStatusesByStage,
} from '@constants';
import {
  CandidateAutoupdatedFields,
  CandidateStatusFormFields,
  CandidateStatusFormTransition,
  CandidateStatusFormValues,
  CandidateStatusPayload,
  IDWHJobOpening,
  IZohoCandidate,
  StatusDataType,
} from '@types';
import { flatten, isEqual, keyBy, max, uniqWith, values } from 'lodash';
import { isTruthy } from './isTruthy';
import { checkIfCandidateIsClosed } from './candidateDetails';
import { format } from 'date-fns';

export const getInitialCandidateStatusFormValues = (
  candidate: IZohoCandidate,
  selectedJobOpeningId: string | null,
  preselectedStage: CandidateStatusStage | null,
): CandidateStatusFormValues => {
  const transitions: CandidateStatusFormTransition[] = [
    { stage: preselectedStage || '', status: '', jobOpeningId: null },
  ];
  selectedJobOpeningId &&
    transitions.push({
      stage: preselectedStage || '',
      status: '',
      jobOpeningId: selectedJobOpeningId,
    });

  return {
    transitions,
    excludedTransitions: [],
    transitionForms: [],
    Lead_closed_because_NEW: candidate.Lead_closed_because_NEW,
    // Interested Status
    Source: candidate.Source,
    Type_of_channel: candidate.Type_of_channel,
    Type_of_Inbound_channel: candidate.Type_of_Inbound_channel,
    Type_of_Outbound_channel: candidate.Type_of_Outbound_channel,
    Interested_in_JO: candidate.Interested_in_JO,
    LeadGen_owner: candidate.LeadGen_owner,
    Leads_reply_Date: candidate.Leads_reply_Date,
    Date_of_last_apply: candidate.Date_of_last_apply,
    // Video Interview
    VI_sent_date: candidate.VI_sent_date,
    VI_date: candidate.VI_date,
    Video_Interview_Link: candidate.Video_Interview_Link,
    VI_feedback: candidate.VI_feedback,
    // Test Task
    Test_Task_sent_date: candidate.Test_Task_sent_date,
    Test_Task_Technology: candidate.Test_Task_Technology,
    Test_Task_sent_by: candidate.Test_Task_sent_by,
    Test_Task_Date: candidate.Test_Task_Date,
    Codility_Result: candidate.Codility_Result,
    Test_Task_Link: candidate.Test_Task_Link,
    Test_Task_Feedback: candidate.Test_Task_Feedback,
    // Intro Call
    Email: candidate.Email,
    First_Name: candidate.First_Name,
    Last_Name: candidate.Last_Name,
    'Dev_-_QA-AQA': candidate['Dev_-_QA-AQA'],
    Primary_Skill_Set: candidate.Primary_Skill_Set,
    Secondary_Skill_Set: candidate.Secondary_Skill_Set,
    Skill_Set: candidate.Skill_Set,
    Full_Stack: candidate.Full_Stack,
    Type_of_Developer: candidate.Type_of_Developer,
    Technical_Flow: candidate.Technical_Flow,
    English_level:
      !candidate.English_level || candidate.English_level === 'Not checked yet'
        ? null
        : candidate.English_level,
    Seniority_Level: candidate.Seniority_Level,
    LinkedIn_URL: candidate.LinkedIn_URL,
    Location_Country: candidate.Location_Country,
    Location_City: candidate.Location_City,
    Hourly_Rate_Expected: candidate.Hourly_Rate_Expected,
    Notice_period_from_Offer: candidate.Notice_period_from_Offer,
    Current_Company: candidate.Current_Company,
    Current_employment_type: candidate.Current_employment_type,
    Expected_employment_type: candidate.Expected_employment_type,
    Hourly_Rate_Current: candidate.Hourly_Rate_Current,
    Leads_owner_2018: candidate.Leads_owner_2018,
    Date_of_Prescreen_Planned: candidate.Date_of_Prescreen_Planned,
    Talked_with:
      candidate.Talked_with || [candidate.Leads_owner_2018].filter(isTruthy),
    Recruiters_Feedback: candidate.Recruiters_Feedback,
    Prescreen_failed_because: candidate.Prescreen_failed_because,
    Date_of_Prescreen: candidate.Date_of_Prescreen,
    Calendly_calendar: candidate.Calendly_calendar,
    // Technical Interview
    Test_Task_Reviewers: candidate.Test_Task_Reviewers,
    Date_of_Tech_Interview_planned: candidate.Date_of_Tech_Interview_planned,
    Technical_Conslusion: candidate.Technical_Conslusion,
    // Team Lead Intro
    TL_Intro: candidate.TL_Intro,
    TL_intro_date: candidate.TL_intro_date,
    TL_intro_feedback: candidate.TL_intro_feedback,
    TI_Suggested_Seniority: candidate.TI_Suggested_Seniority,
    // Client Interview
    AE_Formatted_CV_Link: candidate.AE_Formatted_CV_Link,
    Clients_Submission: candidate.Clients_Submission,
    Date_of_Submission: candidate.Date_of_Submission,
    Date_of_Clients_Interview: candidate.Date_of_Clients_Interview,
    "Client's_Feedback": candidate["Client's_Feedback"],
    Hourly_Rate_Offer1: candidate.Hourly_Rate_Offer1,
    Final_decision_from_client: candidate.Final_decision_from_client,
    // SOW
    SOW_signed_date: candidate.SOW_signed_date,
    // Hiring
    Date_of_offer_done: candidate.Date_of_offer_done,
    Hot_Pipeline_Start_Date: candidate.Hot_Pipeline_Start_Date,
    Hot_Pipeline_End_Date: candidate.Hot_Pipeline_End_Date,
  };
};

export const getCandidateStages = (
  specialization: string | null,
): CandidateStatusStage[] =>
  !specialization || EnineeringSpecializations.includes(specialization)
    ? EngineeringCandidateStagesOrder
    : NonEngineeringCandidateStagesOrder;

export const getStatusFormData = (
  status: CandidateStatus,
  stage: CandidateStatusStage,
  formValues: CandidateStatusFormValues,
  candidateDetails: IZohoCandidate,
): {
  formFieldsNames: Array<keyof CandidateStatusFormFields> | null;
  autoupdatedFields: Partial<CandidateAutoupdatedFields> | null;
} => {
  const getFormData = CandidateStatusFormConfig[status]!;

  const { formFieldsNames, autoupdatedFields } = getFormData(stage, formValues);

  const mainProfileStatusTransition = formValues.transitions.find(
    (t) => !t.jobOpeningId,
  );
  const targetProfileStatus = mainProfileStatusTransition?.status;
  const isCandidateClosed = checkIfCandidateIsClosed(candidateDetails);

  let reopeningFields: Partial<CandidateAutoupdatedFields> | null = null;
  if (
    targetProfileStatus &&
    targetProfileStatus === status &&
    isCandidateClosed &&
    !AllClosedStatuses.includes(targetProfileStatus)
  ) {
    reopeningFields = {
      State_of_Candidate_NEW:
        candidateDetails.State_of_Candidate_NEW?.toLowerCase().includes(
          'qualified',
        )
          ? 'Open Qualified Lead'
          : 'Open Lead',
      Lead_closed_because_NEW: '',
      Date_of_lead_closing: '',
      Stage_of_closing: '',
    };
  }

  return {
    formFieldsNames,
    autoupdatedFields: reopeningFields
      ? {
          ...(reopeningFields || {}),
          ...(autoupdatedFields || {}),
        }
      : autoupdatedFields,
  };
};

export const getMainProfileTransition = (
  transitions: CandidateStatusFormTransition[],
  candidate: IZohoCandidate,
): CandidateStatusFormTransition | null => {
  if (!transitions.length) return null;

  const candidateSpecialization = candidate['Dev_-_QA-AQA'];

  const stagesOrder = getCandidateStages(candidateSpecialization);
  const passedStatuses = flatten(values(PassedStatusesByStage));

  const sortedTransitions = [...transitions].sort((prev, next) => {
    const prevStageIdx = stagesOrder.findIndex((stage) => stage === prev.stage);
    const nextStageIdx = stagesOrder.findIndex((stage) => stage === next.stage);
    const prevStatusIdx = prev.stage
      ? CandidateStatusesByStage[prev.stage].findIndex(
          (status) => status === prev.status,
        )
      : -1;
    const nextStatusIdx = next.stage
      ? CandidateStatusesByStage[next.stage].findIndex(
          (status) => status === next.status,
        )
      : -1;

    return prevStageIdx + prevStatusIdx - (nextStageIdx + nextStatusIdx);
  });

  const hasNonClosedStatusTransition = sortedTransitions.some(
    (e) => !AllClosedStatuses.includes(e.status as CandidateStatus),
  );
  let maxTransition = sortedTransitions.at(-1) as CandidateStatusFormTransition;

  // Set main profile status as latest process status of the stage in case candidate has available opportunities
  if (
    AllClosedStatuses.includes(maxTransition.status as CandidateStatus) &&
    hasNonClosedStatusTransition
  ) {
    const stageStatuses =
      CandidateStatusesByStage[maxTransition.stage as CandidateStatusStage];
    const statusIdx = stageStatuses.findIndex(
      (e) => e === maxTransition.status,
    );
    const excludedStatuses = [
      ...AllClosedStatuses,
      ...passedStatuses,
      ...(ExcludedStatusesByStatus[maxTransition.status as CandidateStatus] ||
        []),
      ...ExcludedTransitionStatuses,
    ];

    const filteredStatuses = stageStatuses.filter(
      (status, idx) => idx < statusIdx && !excludedStatuses.includes(status),
    );

    maxTransition = {
      stage: maxTransition.stage as CandidateStatusStage,
      status: filteredStatuses.at(-1) as CandidateStatus,
      jobOpeningId: null,
    };
  }

  return {
    stage: maxTransition?.stage || '',
    status: maxTransition?.status || '',
    jobOpeningId: null,
  };
};

export const getTransitionForms = (
  transitions: CandidateStatusFormTransition[],
  candidate: IZohoCandidate,
  associatedJopOpenings: IDWHJobOpening[],
): { stage: CandidateStatusStage; status: CandidateStatus }[] => {
  const jobOpeningsById = keyBy(associatedJopOpenings, 'id');
  const stagesOrder = getCandidateStages(candidate['Dev_-_QA-AQA']);

  const transitionForms = transitions.map((transition) => {
    const from = !transition.jobOpeningId
      ? { stage: candidate.Stage, status: candidate.Candidate_Status }
      : {
          stage: jobOpeningsById[transition.jobOpeningId].Stage,
          status: jobOpeningsById[transition.jobOpeningId].Status,
        };
    const to = {
      stage: transition.stage,
      status: transition.status,
    };

    if (!to.stage || !to.status) return [];

    const toStageIdx = stagesOrder.findIndex((e) => e === to.stage);
    const fromStageIdx = max([
      stagesOrder.findIndex((e) => e === from.stage),
      0,
    ])!;

    const isReversed = toStageIdx < fromStageIdx;

    const stagesBeetween = isReversed
      ? [stagesOrder[toStageIdx]]
      : stagesOrder.slice(fromStageIdx, toStageIdx + 1);

    const transitionForms = stagesBeetween.flatMap((stage) => {
      const stageStatuses = CandidateStatusesByStage[stage];

      const passedStatuses = flatten(values(PassedStatusesByStage));

      if (stage === to.stage) {
        const toStatusIdx = stageStatuses.findIndex((e) => e === to.status);
        const fromStatusIdx = stageStatuses.findIndex((e) => e === from.status);
        const isReversed = toStatusIdx < fromStatusIdx;
        const transitionStatuses = isReversed
          ? stageStatuses.slice(0, toStatusIdx + 1)
          : stageStatuses.slice(fromStatusIdx + 1, toStatusIdx + 1);

        const excludedStatuses = [
          ...AllClosedStatuses,
          ...passedStatuses,
          ...(ExcludedStatusesByStatus[to.status as CandidateStatus] || []),
          ...ExcludedTransitionStatuses,
        ];

        return [
          ...transitionStatuses
            .slice(0, -1)
            .filter((e) => !excludedStatuses.includes(e)),
          transitionStatuses.at(-1),
        ]
          .filter(isTruthy)
          .map((stageStatus) => ({
            stage,
            status: stageStatus,
          }));
      }

      const statusIdx = stageStatuses.findIndex((e) => e === from.status);

      return stageStatuses
        .slice(statusIdx + 1)
        .filter(
          (status) =>
            !ExcludedTransitionStatuses.includes(status) &&
            (ProcessStatusesByStage[stage].includes(status) || // Take all process statuses
              !PassedStatusesByStage[stage].findIndex((e) => e == status)), // Take first passed status
        )
        .map((status) => ({
          stage,
          status,
        }));
    });

    return transitionForms;
  });

  return uniqWith(flatten(transitionForms), isEqual).sort(
    (transitionFormA, transitionFormB) => {
      const transitionFormAScore =
        stagesOrder.findIndex((e) => e === transitionFormA.stage) * 100 +
        CandidateStatusesByStage[transitionFormA.stage].findIndex(
          (e) => e === transitionFormA.status,
        );
      const transitionFormBScore =
        stagesOrder.findIndex((e) => e === transitionFormB.stage) * 100 +
        CandidateStatusesByStage[transitionFormB.stage].findIndex(
          (e) => e === transitionFormB.status,
        );

      return transitionFormAScore - transitionFormBScore;
    },
  );
};

export const transformFormValuesToPayload = (
  values: CandidateStatusFormValues,
  candidateId: string,
  candidate: IZohoCandidate,
  associatedJopOpenings: IDWHJobOpening[],
): CandidateStatusPayload => {
  const transitions = values.transitions
    .map((transition) =>
      getTransitionForms([transition], candidate, associatedJopOpenings)
        .filter(
          (transition) =>
            !values.excludedTransitions.find(
              (excludedTransition) =>
                excludedTransition.stage === transition.stage &&
                excludedTransition.status === transition.status,
            ),
        )
        .map(({ status, stage }) => ({
          status,
          stage,
          jobOpeningId: transition.jobOpeningId,
        })),
    )
    .filter((transitions) => transitions.length);

  const statusesData: StatusDataType[] = getTransitionForms(
    values.transitions,
    candidate,
    associatedJopOpenings,
  )
    .filter(
      (transition) =>
        !values.excludedTransitions.find(
          (excludedTransition) =>
            excludedTransition.stage === transition.stage &&
            excludedTransition.status === transition.status,
        ),
    )
    .map((transitionStatus) => {
      const { formFieldsNames, autoupdatedFields } = getStatusFormData(
        transitionStatus.status,
        transitionStatus.stage,
        values,
        candidate,
      );

      const formFields: Partial<CandidateStatusFormFields> = formFieldsNames
        ? formFieldsNames.reduce(
            (acc, fieldName: keyof CandidateStatusFormFields) => {
              return {
                ...acc,
                [fieldName]: values[fieldName],
              };
            },
            {},
          )
        : {};

      return {
        status: transitionStatus.status,
        stage: transitionStatus.stage,
        Stage: transitionStatus.stage,
        ...formFields,
        ...(autoupdatedFields || {}),
      };
    });

  return {
    candidateId,
    payload: {
      transitions,
      statusesData,
    },
  };
};

export const checkIfHasActiveIntroCall = (
  formValues: CandidateStatusFormValues,
) => {
  return (
    formValues.Calendly_calendar === 'Meeting scheduled' &&
    !!formValues.Date_of_Prescreen_Planned &&
    formValues.Date_of_Prescreen_Planned > format(new Date(), 'yyyy-MM-dd')
  );
};
