import { SubmissionStatus } from '@constants';
import * as yup from 'yup';

export const submissionStatusChangeValidationSchema = yup.object({
  closingReason: yup
    .string()
    .optional()
    .max(255)
    .when(['status'], (formData, schema) => {
      const status: SubmissionStatus = formData[0];

      if (SubmissionStatus.Rejected === status)
        return schema.required('Closing reason is required');

      return schema;
    })
    .nullable(),
  scheduledSlot: yup
    .object({
      start: yup.string().nullable().required('Start time is required.'),
      end: yup.string().nullable().required('End time is required.'),
    })
    .nullable()
    .when(['status'], (formData, schema) => {
      const status: SubmissionStatus = formData[0];

      if (SubmissionStatus.CallScheduled === status)
        return schema.required('Scheduled slot is required');

      return schema;
    })
    .nullable(),
});
