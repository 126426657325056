export enum SubmissionStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  CallRequested = 'Call Requested',
  CallCancelled = 'Call Cancelled',
  CallScheduled = 'Call Scheduled',
  CallPassed = 'Call Passed',
  CallFailed = 'Call Failed',
  Rejected = 'Rejected',
  Hired = 'Hired',
}

export enum SubmissionStep {
  Profile = 'Profile',
  Resume = 'Resume',
  VideoInterview = 'Video',
  TestTask = 'Test task',
  Feedback = 'Feedback',
  Interview = 'Interview',
  Recipients = 'Recipients',
  Review = 'Review',
}

export enum ClientFeedbackResolution {
  Rejected = 'Rejected',
  CallRequested = 'Call Requested',
  Hired = 'Hired',
}

export const SubmissionStepsOrdered = [
  SubmissionStep.Profile,
  SubmissionStep.Resume,
  SubmissionStep.VideoInterview,
  SubmissionStep.TestTask,
  SubmissionStep.Feedback,
  SubmissionStep.Interview,
  SubmissionStep.Recipients,
  SubmissionStep.Review,
];

export const SubmissionClosingReasons = [
  'Communication skills',
  'Location',
  'Seniority',
  'Tech skills',
];

export const ResolutionColorBySubmissionStatus: Record<
  SubmissionStatus,
  'text.success' | 'text.secondary'
> = {
  [SubmissionStatus.Draft]: 'text.secondary',
  [SubmissionStatus.Submitted]: 'text.success',
  [SubmissionStatus.CallRequested]: 'text.success',
  [SubmissionStatus.CallCancelled]: 'text.secondary',
  [SubmissionStatus.CallScheduled]: 'text.success',
  [SubmissionStatus.CallPassed]: 'text.success',
  [SubmissionStatus.CallFailed]: 'text.secondary',
  [SubmissionStatus.Rejected]: 'text.secondary',
  [SubmissionStatus.Hired]: 'text.success',
};
