import React, { useCallback } from 'react';
import { getIn } from 'formik';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { DateTimeRangePicker, TextButton } from '@components/common';

import { displayDateTimeInTimezone } from '@utils';
import { utcTimezones } from '@constants';
import { DateTimeRange, SubmissionFormik } from '@types';

export const SubmissionInterviewStep: React.FC<{
  formik: SubmissionFormik;
}> = ({ formik }) => {
  const { values, setFieldValue, handleChange, touched, errors, submitCount } =
    formik;
  const {
    candidateSlots: slots,
    candidateReadyAnytime,
    candidateTimezone,
    clientTimezone,
  } = values.interview || {};
  const hasSlots = !!slots?.length;
  const isDisabledAdding =
    (hasSlots && slots.some((slot) => !slot)) ||
    candidateReadyAnytime ||
    !candidateTimezone ||
    !clientTimezone;
  const hasSectionError =
    (!!getIn(touched, 'interview.candidateSlots') || !!submitCount) &&
    !!getIn(errors, 'interview.candidateSlots');

  const addSlot = useCallback(() => {
    setFieldValue('interview.candidateSlots', [
      ...(slots || []),
      { start: null, end: null },
    ]);
  }, [setFieldValue, slots]);

  const handleSlotChange = (date: DateTimeRange, index: number) => {
    setFieldValue(
      'interview.candidateSlots',
      slots?.map((slot, idx) => (idx === index ? date || null : slot)) || null,
    );
  };

  const handleDeleteSlot = (index: number) => {
    setFieldValue(
      'interview.candidateSlots',
      slots?.filter((_, idx) => idx !== index) || null,
    );
  };

  const renderSelectValue = useCallback((selected: unknown) => {
    const selectedOption = utcTimezones.find(
      (option) => option.value === selected,
    );
    return selectedOption ? (
      <Typography variant="body1">
        {selectedOption.label}, {selectedOption.subLabel}
      </Typography>
    ) : (
      <span>Select timezone</span>
    );
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2} py={2}>
      <Typography variant="h3">
        Candidate’s availability for client interview
      </Typography>
      {hasSectionError && (
        <Typography variant="subtitle2" color="text.danger">
          Candidate's call availability is required
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            id="candidate-timezone"
            name="interview.candidateTimezone"
            label="Candidate timezone"
            value={getIn(values, 'interview.candidateTimezone') ?? ''}
            SelectProps={{
              displayEmpty: true,
              notched: true,
              renderValue: renderSelectValue,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          >
            <MenuItem key="empty-candidate-timezone" value="" disabled>
              Select timezone
            </MenuItem>
            {utcTimezones.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText>{option.label}</ListItemText>
                {option.subLabel && (
                  <Typography fontSize="0.688rem" color="text.secondary">
                    {option.subLabel}
                  </Typography>
                )}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            id="client-timezone"
            name="interview.clientTimezone"
            label="Client timezone"
            value={getIn(values, 'interview.clientTimezone') ?? ''}
            SelectProps={{
              displayEmpty: true,
              notched: true,
              renderValue: renderSelectValue,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          >
            <MenuItem key="empty-client-timezone" value="" disabled>
              Select timezone
            </MenuItem>
            {utcTimezones.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText>{option.label}</ListItemText>
                {option.subLabel && (
                  <Typography fontSize="0.688rem" color="text.secondary">
                    {option.subLabel}
                  </Typography>
                )}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {!slots?.length ? (
            <Stack
              bgcolor="highlight.neutral"
              borderRadius="0.5rem"
              minHeight="13rem"
              justifyContent="center"
              alignItems="center"
              gap="0.5rem"
            >
              <TextButton
                variant="secondary"
                startIcon={<AddCircleOutlineRoundedIcon />}
                disabled={isDisabledAdding}
                onClick={addSlot}
              >
                Add candidate’s slot
              </TextButton>
              <Typography variant="body2" align="center">
                Client will be able to select candidate’s slots or add their own
                slots or shared calendar
              </Typography>
            </Stack>
          ) : (
            <Stack
              marginX="-2rem"
              paddingX="2rem"
              paddingY="2.5rem"
              borderRadius="0.5rem"
              border="0.5rem solid"
              borderColor="highlight.neutral"
              alignItems="flex-start"
              gap={2}
            >
              {slots.map((slot, index) => (
                <Stack key={index} direction="row" gap="1rem">
                  <DateTimeRangePicker
                    timeZone={candidateTimezone!}
                    date={slot}
                    handleChange={(date) => handleSlotChange(date, index)}
                    flex={1}
                  />
                  <Stack direction="row" flex={1}>
                    <Stack flex={1} justifyContent="center">
                      <Typography fontSize="0.75rem" color="text.secondary">
                        Client time
                      </Typography>
                      <Typography variant="body1">
                        {slot.start
                          ? `${displayDateTimeInTimezone(
                              slot.start,
                              clientTimezone!,
                            ).format(
                              'ddd, MMM DD, hh:mm a',
                            )} - ${displayDateTimeInTimezone(
                              slot.end,
                              clientTimezone!,
                            ).format('hh:mm a')}`
                          : '—'}
                      </Typography>
                    </Stack>
                    <IconButton
                      onClick={() => handleDeleteSlot(index)}
                      size="small"
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              ))}
              <TextButton
                variant="secondary"
                startIcon={<AddCircleOutlineRoundedIcon />}
                disabled={isDisabledAdding}
                onClick={addSlot}
              >
                Add candidate’s slot
              </TextButton>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={candidateReadyAnytime || false}
                onChange={(_, checked) =>
                  setFieldValue('interview', {
                    ...values.interview,
                    candidateReadyAnytime: checked,
                    candidateSlots: [],
                  })
                }
              />
            }
            label="Candidate is available anytime, client to provide meeting slots."
          />
        </Grid>
      </Grid>
    </Box>
  );
};
