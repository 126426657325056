export const AppRoutes = {
  HOME: '/',
  JOB_OPENINGS: 'job-openings',
  ON_HOLD_JOB_OPENINGS: 'on-hold-job-openings',
  CREATE_JOB_OPENING: 'job-openings/create',
  NEW_HOME_CANDIDATES: 'candidates/new-home',
  SELFGEN_CANDIDATES: 'candidates/self-gen',
  ACTION_REPORT: 'action-report',
  APPLIED_CANDIDATES: 'candidates/applied',
  TT_PASSED_CANDIDATES: 'candidates/tt-passed',
  IC_SCHEDULED_CANDIDATES: 'candidates/ic-scheduled',
  IC_DONE_CANDIDATES: 'candidates/ic-done',
  IC_PASSED_CANDIDATES: 'candidates/ic-passed',
  TI_PASSED_CANDIDATES: 'candidates/ti-passed',
  SUBMITTED_CANDIDATES: 'candidates/submitted',
  CANDIDATES: 'candidates',
  SIGN_IN: 'sign-in',
  AE_SCORE: 'ae-score',
  SUBMISSIONS: 'submissions',
};

export const SearchParams = {
  SELECTED_CANDIDATE: 'selectedCandidate',
  SELECTED_CANDIDATE_TAB: 'selectedTab',
  DUPLICATE_JOB_OPENING_ID: 'duplicateJobOpeningId',
  SUBMISSION_JOBOPENING_ID: 'submissionJobOpeningId',
};
