import { PaletteOptions } from '@mui/material/styles/createPalette';
import { brandAccentColor } from './variables';

export default <PaletteOptions>{
  primary: {
    main: 'rgba(0, 0, 0, 1)',
  },
  error: {
    main: 'rgba(255, 46, 0, 1)',
  },
  secondary: {
    main: 'rgba(0, 0, 0, 0.5)',
  },
  text: {
    invertedPrimary: 'rgba(255, 255, 255, 1)',
    invertedSecondary: 'rgba(255, 255, 255, 0.8)',
    purple: '#BB28BE',
    brand: '#FF7A00',
    link: '#3F8CFF',
    linkLight: '#E8F3FE',
    linkDark: '#2F60B2',
    success: '#4CAF50',
    danger: '#FF4C4C',
    warningText: '#663C00',
    infoText: '#663C00',
    grey: 'rgba(0, 0, 0, 0.5)',
    disabled: 'rgba(0, 0, 0, 0.26)',
  },
  brand: {
    accent: brandAccentColor,
    secondary: '#BB28BE',
  },
  button: {
    outlined: {
      primary: {
        text: '#3F8CFF',
        border: '#2196F380',
        background: '#FFF',
        textHover: '#3F8CFF',
        borderHover: '#2196F380',
        backgroundHover: '#E8F3FE',
      },
    },
    contained: {
      primary: {
        text: '#FFF',
        border: '#3F8CFF',
        background: '#3F8CFF',
        textHover: '#FFF',
        borderHover: '#2F60B2',
        backgroundHover: '#2F60B2',
      },
      secondary: {
        text: '#3F8CFF',
        border: '#E8F3FE',
        background: '#E8F3FE',
        textHover: '#3F8CFF',
        borderHover: '#F6F9FD',
        backgroundHover: '#F6F9FD',
      },
    },
    primary: {
      front: '#3F8CFF',
      frontHover: '#3F8CFF',
      frontFocus: '#3F8CFF',
      frontActive: '#3F8CFF',
      frontDisabled: '#F6F6F6',
      text: '#FFFFFF',
      textDisabled: 'rgba(0, 0, 0, 0.5)',
    },
    secondary: {
      front: '#007CFF14',
      frontHover: '#007CFF20',
      frontFocus: '#007CFF20',
      frontActive: '#007CFF20',
      frontDisabled: '#E0E0E0',
      text: '#3F8CFF',
      textDisabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  background: {
    brandAccent: brandAccentColor,
    brandSecondary: 'rgba(187, 40, 190, 1)',
    brandTransparent: 'rgba(255, 189, 128, 0.2)',
    brandLinearGradient: 'linear-gradient(90deg, #FF7A00 50%, #FF9801 100%)',
    brandRadialGradient:
      'radial-gradient(37.64% 121.95% at 67.5% 57.31%, #FFD600 0%, rgba(255, 122, 0, 0) 100%)',
    inputDisabled: 'rgba(246, 249, 253, 1)',
    buttonHover: 'rgba(48, 48, 48, 1)',
    buttonHoverWhite: 'rgba(255, 255, 255, 0.15)',
    positionLink: 'rgba(255, 255, 255, 0.1)',
    socialLink: 'rgba(255, 255, 255, 0.1)',
    stepsCircle: 'rgba(255, 183, 77, 1)',
    backgroundPrimary: 'rgba(246, 248, 252, 1)',
    brandLight: 'rgba(255, 242, 222, 1)',
    whiteLinearGradient:
      'linear-gradient(180deg, transparent 50%, #FFFFFF 100%)',
    disabled: 'rgba(0, 0, 0, 0.12)',
    warningFill: '#FFF4E5',
    infoFill: '#E5F6FD',
    link: '#3F8CFF',
  },
  highlight: {
    section: '#E8F3FE',
    sectionLight: '#F6F9FD',
    accent: '#fff4cc',
    actionable: '#E6F3FF',
    actionableHover: 'rgba(0, 118, 235, 0.1)',
    neutral: '#F6F9FD',
    hover: '#eff6f9',
    white: '#ffffff',
    success: '#4CAF50',
    error: '#ffdecc',
    lightError: '#FFC2B5',
    textAccentShadow: `0px 0px 8px ${brandAccentColor}, 0px 0px 2px ${brandAccentColor}, 0px 0px 2px ${brandAccentColor}`,
    boxShadowAccent: 'rgba(185, 106, 32, 0.24)',
    fileSelection: '#0076eb',
    fileSelectionHover: '#016fdc',
    brandSecondaryLight: 'rgba(187, 40, 190, 0.08)',
    scoreGreen: 'rgba(75, 166, 1, 1)',
    scoreYellow: 'rgba(255, 175, 0, 1)',
    scoreBlack: 'rgba(0, 0, 0, 1)',
    lowSeverity: '#00000014',
    mediumSeverity: '#FFECB3',
    highSeverity: '#FF696C',
  },
};
