import { ReactNode } from 'react';
import { FormikErrors } from 'formik';

import { Box, Typography } from '@mui/material';
import { LoaderSkeleton, ForbiddenView } from '@components';
import { SubmisionFormHeader } from './components/SubmisionFormHeader';
import { SubmissionFooter } from './components/SubmissionFooter';

import { IZohoCandidate, SubmissionFormValues } from '@types';
import {
  SubmissionStatus,
  SubmissionStep,
  LOADER_SKELETON_TYPES,
} from '@constants';

const WIDER_LAYOUT_STEPS = [SubmissionStep.Review, SubmissionStep.Interview];

export const SubmissionFormLayout: React.FC<{
  candidateDetails: IZohoCandidate | null;
  activeStep: SubmissionStep;
  errors: FormikErrors<SubmissionFormValues>;
  isLoading: boolean;
  isLoadingFailed: boolean;
  isForbidden: boolean;
  isPerformingAction: boolean;
  submissionStatus: SubmissionStatus | null;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onSelectStep: (selectedStep: SubmissionStep) => void;
  onSubmitClick: () => void;
  onSaveDraftClick: () => void;
  children: ReactNode;
}> = ({
  candidateDetails,
  activeStep,
  errors,
  isLoading,
  isLoadingFailed,
  isForbidden,
  isPerformingAction,
  submissionStatus,
  onPreviousClick,
  onNextClick,
  onSelectStep,
  onSubmitClick,
  onSaveDraftClick,
  children,
}) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <SubmisionFormHeader
        candidateDetails={candidateDetails}
        activeStep={activeStep}
        errors={errors}
        onSelectStep={onSelectStep}
      />
      <Box display="flex" justifyContent="center" flex={1}>
        <Box
          maxWidth={WIDER_LAYOUT_STEPS.includes(activeStep) ? '660px' : '450px'}
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          position="relative"
          pb={3}
          gap={1}
        >
          {isLoading && (
            <Box height="calc(100vh - 100px)" width="100%">
              <LoaderSkeleton type={LOADER_SKELETON_TYPES.ROWS} />
            </Box>
          )}
          {isLoadingFailed && (
            <Typography variant="h3" textAlign="center" mt={4}>
              Candidate not found
            </Typography>
          )}
          {isForbidden && <ForbiddenView />}
          {!isLoading && !isLoadingFailed && !isForbidden && (
            <>
              <Box width="100%" flex={1}>
                {children}
              </Box>
              <Box width="100%">
                <SubmissionFooter
                  activeStep={activeStep}
                  isPerformingAction={isPerformingAction}
                  submissionStatus={submissionStatus}
                  onPreviousClick={onPreviousClick}
                  onNextClick={onNextClick}
                  onSubmitClick={onSubmitClick}
                  onSaveDraftClick={onSaveDraftClick}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
