import { useCallback } from 'react';

import { Chip, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import DomainIcon from '@mui/icons-material/Domain';
import BuildIcon from '@mui/icons-material/Build';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import { Flex } from '@components';

import {
  JobOpeningCrucialRequirementPriority,
  JobOpeningCrucialRequirementPriorityLabel,
  JobOpeningCrucialRequirementCategory,
} from '@constants';

const IconByCategory: Record<
  JobOpeningCrucialRequirementCategory,
  JSX.Element
> = {
  [JobOpeningCrucialRequirementCategory.Education]: <SchoolIcon />,
  [JobOpeningCrucialRequirementCategory.DomainKnowledge]: <DomainIcon />,
  [JobOpeningCrucialRequirementCategory.Technology]: <BuildIcon />,
  [JobOpeningCrucialRequirementCategory.Visa]: <CardTravelIcon />,
};

export const CrucialRequirementsItem: React.FC<{
  name: string;
  category: JobOpeningCrucialRequirementCategory;
  priority: JobOpeningCrucialRequirementPriority;
  onChangePriority: (
    requirement: string,
    priority: JobOpeningCrucialRequirementPriority,
  ) => void;
  onRemoveItem: (requirement: string) => void;
}> = ({ name, category, priority, onChangePriority, onRemoveItem }) => {
  const onDelete = useCallback(() => onRemoveItem(name), [name, onRemoveItem]);
  const onChange = useCallback(
    (nextPriority: JobOpeningCrucialRequirementPriority) =>
      onChangePriority(name, nextPriority),
    [name, onChangePriority],
  );

  return (
    <Flex
      justifyContent="space-between"
      gap={2}
      sx={(theme) => ({
        paddingBottom: '8px',
        borderBottom: `1px solid ${theme.palette.background.disabled}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      })}
    >
      <Chip label={name} icon={IconByCategory[category]} onDelete={onDelete} />
      <RadioGroup
        value={priority}
        onChange={(e) =>
          onChange(e.target.value as JobOpeningCrucialRequirementPriority)
        }
        sx={{ gap: 2, flexDirection: 'row' }}
      >
        {Object.keys(JobOpeningCrucialRequirementPriorityLabel).map(
          (option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio disableRipple />}
              label={
                JobOpeningCrucialRequirementPriorityLabel[
                  option as JobOpeningCrucialRequirementPriority
                ]
              }
            />
          ),
        )}
      </RadioGroup>
    </Flex>
  );
};
